import React from "react"
import InformationenDatenverarbeitungContent from "../components/InformationenDatenverarbeitungContent"
import Layout from "../components/Layout"
import Section from "../components/Section"

export interface InformationenDatenverarbeitungProps {}

const InformationenDatenverarbeitung: React.SFC<InformationenDatenverarbeitungProps> = () => {
  return (
    <Layout>
      <Section>
        <InformationenDatenverarbeitungContent />
      </Section>
    </Layout>
  )
}

export default InformationenDatenverarbeitung
