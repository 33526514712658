import { Link } from "gatsby"
import React from "react"
import { H1 } from "./text/H1"
import { LinkType, OutsideLink } from "./text/OutsideLink"
import { Paragraph } from "./text/Paragraph"

export interface InformationenDatenverarbeitungContentProps {}

const InformationenDatenverarbeitungContent: React.SFC<InformationenDatenverarbeitungContentProps> = () => {
  return (
    <>
      <H1 text="Information zur Datenverarbeitung" />
      <Paragraph>
        Im Zusammenhang mit unseren Dienstleistungen verarbeiten wir persönliche
        Daten von Ihnen. Wir möchten Ihnen einige Informationen nach Artikel 12
        und 13 der europäischen Datenschutzgrundverordnung zur Verarbeitung
        Ihrer Daten geben, sowie über Ihre Rechte im Zusammenhang mit der
        Erhebung und Speicherung der Daten informieren.
      </Paragraph>
      <Paragraph>
        <strong>
          In der App „Die Psychotherapie App“ werden keine personenbezogenen
          Daten benötigt oder gespeichert.
        </strong>
      </Paragraph>
      <Paragraph>
        Die Information zur Datenverarbeitung für „Die Psychotherapie App“
        finden Sie{" "}
        <Link
          className="text-primary-600 hover:text-primary-800 no-underline"
          to="/datenschutz-app"
        >
          hier
        </Link>
        .
      </Paragraph>
      <Paragraph>
        Verantwortlich für den Datenschutz ist die DPTA Die Psychotherapie App
        UG (haftungsbeschränkt), Kroatengasse 3, 97070 Würzburg,{" "}
        <OutsideLink
          href="kontakt@diepsychotherapieapp.de"
          type={LinkType.mail}
        />
        , Telefon <OutsideLink href="+49 176 43746921" type={LinkType.phone} />
      </Paragraph>
      <Paragraph>
        Unseren Datenschutzbeauftragten erreichen Sie unter{" "}
        <OutsideLink
          href="datenschutz@diepsychotherapieapp.de"
          type={LinkType.mail}
        />
        , <OutsideLink href="+49 6021 624384" type={LinkType.phone} />.
      </Paragraph>
      <Paragraph>
        Zweck der Datenverarbeitung ist es u. A. Ihnen unsere Dienstleistungen
        zur Verfügung zu stellen, unser Unternehmen zu führen, unsere
        vertraglichen Verpflichtungen zu erfüllen oder die Erfüllung
        gesetzlicher Regelungen. Haben Sie uns Bewerbungsdaten zugesendet,
        werden diese im Bewerbungsverfahren verwendet.
      </Paragraph>
      <Paragraph>
        Erfassen dürfen wir Ihre Daten, weil
        <ul className="list-disc list-inside">
          <li>
            wir eine vertragliche Leistung für Sie erbringen (Art. 6, Abs. 1,
            lit. b DSGVO)
          </li>
          <li>
            wir ein berechtigtes Interesse haben (Art. 6, Abs. 1, lit. f, DSGVO)
          </li>
          <li>
            weil wir rechtlich verpflichtet sind, Ihre Daten zu erfassen (Art. 6
            Abs. 1 lit. c, DSGVO)
          </li>
          <li>
            Sie der Erfassung zugestimmt haben (Art. 6 Abs. 1 lit. a, DSGVO)
          </li>
          <li>
            da Sie sich bei uns beworben haben (§ 26 BDSG in der ab dem
            25.05.2018 geltenden Fassung)
          </li>
        </ul>
      </Paragraph>
      <Paragraph>
        Personenbezogene Daten im Zusammenhang mit dem Geschäftsbetrieb (Mails,
        Newsletterdaten, Rechnungsdaten etc.) werden von unseren Mitarbeitern
        verwendet, um unsere Verpflichtungen gegenüber Ihnen zu erfüllen. Wenn
        es zur Leistungserbringung oder aus anderen Gründen notwendig ist, geben
        wir Ihre Daten u.U. an unsere Lieferanten, Partner, Dienstleister oder
        öffentliche Stellen weiter. Selbstverständlich geben wir Daten nur
        weiter, wenn dies notwendig ist.
      </Paragraph>
      <Paragraph>
        Die Daten werden auf Servern in Europa und den USA gespeichert. Bitte
        beachten Sie, dass nach einem Urteil des europäischen Gerichtshofs die
        Privacy-Shield-Vereinbarung mit US-Unternehmen kein mit der EU
        vergleichbares Datenschutzniveau bietet, auch wenn die Daten in
        Deutschland gespeichert werden.{" "}
      </Paragraph>
      <Paragraph>
        Bei allen Daten prüfen wir jährlich, ob wir noch eine Geschäftsbeziehung
        mit Ihnen haben. 3 Jahre nach Ende der Geschäftsbeziehung werden alle
        Daten gelöscht, die wir nicht mehr benötigen. Für Daten, die
        gesetzlichen Aufbewahrungsfristen unterliegen, gelten diese
        Aufbewahrungsfristen (i.d.R. 6 oder 10 Jahre).
        <br />
        Bewerbungsdaten werden 6 Monate nach Ende des Bewerberverfahrens
        gelöscht, es sei denn, es kommt ein Beschäftigungsverhältnis zustande.
        Sollten wir die Daten zur Abwehr von Ansprüchen benötigen, werden die
        Daten bis 4 Jahre nach Ende des Verfahrens gespeichert. Haben Sie der
        der Aufnahme in unseren Bewerberpool zugestimmt, werden Ihre Daten nach
        2 Jahren gelöscht. Ausgenommen von den obigen Löschfristen sind Daten,
        die wir ausschließlich zu Sicherungszwecken gespeichert haben.
      </Paragraph>
      <Paragraph>
        Sie können jederzeit gegenüber uns Auskunft, Berichtigung, Löschung,
        Sperrung, Einschränkung der Verarbeitung und Übergabe Ihrer
        personenbezogenen Daten verlangen. Beruht die Erfassung auf eine
        Einwilligung, kann die Einwilligung jederzeit widerrufen werden. Der
        Widerruf hat keinen Einfluss auf die Rechtmäßigkeit der schon
        gespeicherten Daten.
        <br /> Wenn Sie der Ansicht sind, dass Ihre Daten rechtswidrig
        verarbeitet werden, können Sie Beschwerde bei einer
        Datenschutz-Aufsichtsbehörde einlegen.
      </Paragraph>
      <Paragraph>
        Weitere Informationen zum Datenschutz, insbesondere zur Webseite,
        Newsletter und Fragebögen finden Sie in unserer allgemeinen{" "}
        <Link
          className="text-primary-600 hover:text-primary-800 no-underline"
          to="/datenschutzerklaerung"
        >
          Datenschutzerklärung
        </Link>
        .
      </Paragraph>
    </>
  )
}

export default InformationenDatenverarbeitungContent
